<template>
    <v-container>
        <v-form
            v-model="form.valid"
            ref="selectEpisodeForm"
            @submit.prevent="goToEpisode"
        >
            <v-card color="accent" dark max-width="500" class="mx-auto">
                <v-card-text class="pb-0">
                    <!-- <v-text-field
                        v-model="form.showId"
                        label="Show ID"
                        :rules="form.showIdRules"
                        color="white"
                        outlined
                        dark
                    ></v-text-field> -->

                    <v-text-field
                        v-model="form.episode"
                        label="Episode ID"
                        :rules="form.episodeRules"
                        color="white"
                        outlined
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-container>
                    <v-row justify="space-between">
                        <v-col class="col-auto py-0">
                            <v-btn
                                color="accent2"
                                type="submit"
                                dark
                                class="mr-1 my-0"
                            >
                                Submit
                            </v-btn>
                        </v-col>
                        <v-col class="col-auto py-0">
                            <v-btn
                                text
                                dark
                                class="my-0"
                                @click="form.showId = null; form.episode = null"
                            >
                                Clear
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-container>

                    <!--                    <div class="d-flex flex-wrapd justify-space-between">-->
                    <!--                        <div>-->
                    <!--                            <v-btn-->
                    <!--                                color="accent2"-->
                    <!--                                type="submit"-->
                    <!--                                dark-->
                    <!--                                class="mr-1"-->
                    <!--                            >-->
                    <!--                                Submit-->
                    <!--                            </v-btn>-->
                    <!--                        </div>-->
                    <!--                        <div>-->
                    <!--                            <v-btn-->
                    <!--                                text-->
                    <!--                                dark-->
                    <!--                                @click="form.showId = null; form.episode = null"-->
                    <!--                            >-->
                    <!--                                Clear-->
                    <!--                            </v-btn>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </v-card-actions>
            </v-card>
        </v-form>
    </v-container>
</template>

<script>
    export default {
        name: 'Home',

        props: ['darkMode'],

        data: () => ({
            form: {
                valid: false,
                showId: null,
                ind: 1,
                episode: null,
                showIdRules: [
                    v => !!v || 'Show ID is required',
                ],
                episodeRules: [
                    v => !!v || 'Episode is required',
                ],
            }
        }),

        methods: {
            goToEpisode() {
                this.$refs.selectEpisodeForm.validate();

                if (this.form.valid) {
                    this.$router.push({
                        name: 'PlayerShort',
                        params: {episode: `${this.form.episode}`}
                    });
                }
            }
        }
    }
</script>
