import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isLoggedIn: false,
        jwtToken: null,
        jwtSource: null
    },
    mutations: {
        login(state, jwt) {
            state.jwtToken = jwt.token;
            state.jwtSource = jwt.source ? jwt.source : null;
            state.isLoggedIn = true;
        },
        logout(state) {
            state.jwtToken = null;
            state.jwtSource = null;
            state.isLoggedIn = false;
        },
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page
        if (!store.state.isLoggedIn) {
            const token = to.query.token;
            const source = to.query.source;
            if (token) {
                store.commit('login', {token, source});
                // remove token from URL now that we already grabbed it
                delete to.query.token;
                delete to.query.source;
                router.replace({
                    ...to,
                    query: to.query
                })

                next();
            } else {
                next({ name: 'Login', query: { redirect: window.location.href } });
            }
        } else {
            next(); // go to wherever I'm going
        }
    } else {
        next(); // does not require auth, make sure to always call next()!
    }
})

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
