import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import DatetimePicker from 'vuetify-datetime-picker'
// import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(Vuetify, {
    options: {
        customProperties: true
    }
});

Vue.use(DatetimePicker);

export default new Vuetify({
    theme: {
        themes: {

        }
    }
});
