import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/play/:episode',
        name: 'PlayerShort',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "player" */ '../views/Player.vue'),
          meta: {
              requiresAuth: true
          }
      },
    {
      path: '/player/:showId/:ind/:episode',
      name: 'Player',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "player" */ '../views/Player.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transcript/:entityId/editor',
        name: 'CCEditor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "cceditor" */ '../views/CCEditor.vue'),
          meta: {
              requiresAuth: true
          }
      },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    //base: 'qa/',
    routes
})

export default router
