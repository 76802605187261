<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
            dense
            v-if="!chromeless"
        >
            <div class="d-flex align-center">
                <span class="title font-weight-regular">
                    <!-- <v-icon class="mr-2">mdi-radio</v-icon> -->
                    <!-- <v-icon class="mr-2">mdi-audio-video</v-icon> -->
                    <v-icon class="mr-2">mdi-speaker-wireless</v-icon>
                    AOD QC Player
                </span>
            </div>

            <v-spacer></v-spacer>

            <v-switch
                v-model="darkMode.value"
                class="mr-5"
                color="light-blue lighten-4"
                prepend-icon="mdi-weather-sunny"
                append-icon="mdi-weather-night"
                hide-details
            ></v-switch>            

            <v-chip color="primary" x-small class="mr-5">{{ version }}</v-chip>

            <v-menu
                v-model="menuHelp"
                :close-on-content-click="false"
                :nudge-width="400"
                max-width="600"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="accent2" class="mr-5" v-bind="attrs" v-on="on">
                        <v-icon small class="mr-1">mdi-help</v-icon>Help
                    </v-btn>
                </template>

                <v-card dark color="accent2" elevation="10" class="scroll" height="500">
                    <v-card-title class="d-flex justify-space-between font-weight-regular">
                        AOD QC Player Help
                        <v-btn icon @click="menuHelp = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="white--text">
                        <p class="text-justify">The AOD QC Player allows you to listen to an AOD episode to verify the audio, manage the closed captions (if enabled for the episode), and publish or reject the episode.</p>

                        <h3 class="font-weight-regular mb-2">Listening to Audio</h3>
                        <p class="text-justify">The audio player allows you to select the bitrate of the audio stream to verify each quality level works properly. The <v-icon size="20">mdi-rewind-10</v-icon> and <v-icon size="20">mdi-fast-forward-10</v-icon> buttons allow you to skip back or forward 10 seconds, while the <v-icon size="20">mdi-skip-backward</v-icon> and <v-icon size="20">mdi-skip-forward</v-icon> buttons allow you to go back or forward one segment in the episode.</p>

                        <p class="text-justify">You can click on the album artwork thumbnail to see a larger version of it to be able to inspect it more clearly. As with many other audio players, you can use the jog bar to seek anywhere within the episode, and it will show the current seek point within the file and how much of the episode has been downloaded in the browser.</p>

                        <h3 class="font-weight-regular mb-2">Managing Closed Captions</h3>
                        <p class="text-justify">If Closed Captions are enabled for this episode, you can use the QC Player to edit the captions, assign speaker names/labels, switch between previous versions, and publish the Closed Captions independently from the episode.</p>

                        <h3 class="font-weight-regular mb-2">Approve or Reject the Episode</h3>
                        <p class="text-justify">Click on the green Approve Episode button when ready to approve the episode for publishing. You will be asked to confirm, and choose whether to approve the episode along with the Closed Captions, or only the episode itself.</p>

                        <p class="text-justify">If you want to reject the episode instead, click the red Reject Episode button and select a reason for rejection from the dropdown menu. An additional field for Rejection Details will appear for some reasons where you can specify where in the episode the problem occurs to provide feedback.</p>

                        <h3 class="font-weight-regular mb-2">Closed Caption Button Reference</h3>
                        <v-simple-table class="bg-inherit">
                            <tr>
                                <th class="text-left">Button</th>
                                <th class="text-left">Description</th>
                            </tr>
                            <tr>
                                <th class="text-left font-weight-regular pr-3 py-1"><v-icon class="mr-1">mdi-closed-caption</v-icon>Required</th>
                                <td>Whether the episode requires Closed Captions or not</td>
                            </tr>
                            <tr>
                                <th class="text-left font-weight-regular pr-3 py-1"><v-btn small color="primary"><v-icon small class="mr-1">mdi-numeric-1-box-multiple</v-icon>Versions<v-icon class="ml-1">mdi-chevron-down</v-icon></v-btn></th>
                                <td>Select a previously saved version</td>
                            </tr>
                            <tr>
                                <th class="text-left font-weight-regular pr-3 py-1"><v-btn small color="primary"><v-icon class="mr-1">mdi-account-voice</v-icon>Speakers</v-btn></th>
                                <td>Edit the speaker names or labels</td>
                            </tr>
                            <tr>
                                <th class="text-left font-weight-regular pr-3 py-1"><v-btn small color="primary"><v-icon class="mr-1">mdi-floppy</v-icon>Save</v-btn></th>
                                <td>Save the current draft as a new version without publishing it</td>
                            </tr>
                            <tr>
                                <th class="text-left font-weight-regular pr-3 py-1"><v-btn small color="success"><v-icon class="mr-1">mdi-closed-caption</v-icon>Publish</v-btn></th>
                                <td>Publish the selected version in the editor to make it active</td>
                            </tr>
                            <tr>
                                <th class="text-left font-weight-regular pr-3 py-1"><v-btn small color="error"><v-icon class="mr-1">mdi-closed-caption</v-icon>Unpublish</v-btn></th>
                                <td>Unpublish the selected version to make it inactive</td>
                            </tr>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-menu>

            <v-btn color="error" small @click="logout">Logout<v-icon small class="ml-1 mr-n1">mdi-logout</v-icon></v-btn>
        </v-app-bar>

        <v-main>
            <router-view :dark-mode="darkMode"/>
        </v-main>
    </v-app>
</template>

<style scoped>
    .scroll {
      overflow-y: auto;
    }

    .bg-inherit {
        background-color: inherit !important;
    }
</style>

<script>
    import { version } from '../package.json';
    import { useDark } from '@vueuse/core';
    import { find } from 'lodash';

    export default {
        name: 'App',

        data: () => ({
            version: version ? `v${version}` : 'DEV',
            darkMode: false,
            chromeless: true,
            useSSO: window.__env.useSSO,
            ssoServer: window.__env.ssoURL,
            menuHelp: false,
            theme: 'sxm',
            themes: [
                {
                    name: 'sxmlegacy',
                    light: {
                        primary: '#322790',
                        secondary: '#2196F3',
                        accent: '#2196F3',
                        accent2: '#c5169c',
                        accent3: '#1F4F88',
                        error: '#FF5252',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#FFC107',
                    },
                    dark: {
                        primary: '#322790',
                        secondary: '#2196F3',
                        accent: '#2196F3',
                        accent2: '#c5169c',
                        accent3: '#1F4F88',
                        error: '#FF5252',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#FFC107',
                    }
                },
                {
                    name: 'sxm',
                    light: {
                        primary: '#0000EB',
                        secondary: '#2196F3',
                        accent: '#0000EB',
                        accent2: '#191919',
                        accent3: '#191919',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#FFC107',
                    },
                    dark: {
                        primary: '#0000EB',
                        secondary: '#2196F3',
                        accent: '#0000EB',
                        accent2: '#191919',
                        accent3: '#191919',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#FFC107',
                    }
                },
                {
                    name: 'simplecast',
                    light: {
                        primary: '#000000',
                        secondary: '#666666',
                        accent: '#000000',
                        accent2: '#555555',
                        accent3: '#222222',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#fce868',
                    },
                    dark: {
                        primary: '#333333',
                        secondary: '#666666',
                        accent: '#FFFFFF',
                        accent2: '#666666',
                        accent3: '#222222',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#fce868',
                    }
                },
                {
                    name: 'stitcher',
                    light: {
                        primary: '#1c137c',
                        secondary: '#410B78',
                        accent: '#1c137c',
                        accent2: '#410B78',
                        accent3: '#0B3D74',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#fce868',
                    },
                    dark: {
                        primary: '#1c137c',
                        secondary: '#410B78',
                        accent: '#1c137c',
                        accent2: '#410B78',
                        accent3: '#0B3D74',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#fce868',
                    }
                },
                {
                    name: 'blackwhite',
                    light: {
                        primary: '#000000',
                        secondary: '#666666',
                        accent: '#000000',
                        accent2: '#555555',
                        accent3: '#222222',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#fce868',
                    },
                    dark: {
                        primary: '#333333',
                        secondary: '#666666',
                        accent: '#FFFFFF',
                        accent2: '#666666',
                        accent3: '#222222',
                        error: '#e32c36',
                        info: '#2196F3',
                        success: '#4CAF50',
                        warning: '#fce868',
                    }
                }
            ]
        }),
        
        computed: {
            ssoURL() {
                return (this.ssoServer.https ? 'https://' : 'http://') + this.ssoServer.host + (!this.ssoServer.https && this.ssoServer.port !== 80 || this.ssoServer.https && this.ssoServer.port !== 443 ? ':' + this.ssoServer.port : '') + this.ssoServer.path;
            },
        },

        watch: {
            '$route.query'(){
                this.chromeless = !!this.$route.query.chromeless;
                this.theme = this.$route.query.theme || 'sxm';
                this.setTheme(find(this.themes, {name: this.theme}) || find(this.themes, {name: 'sxm'}));
            }
        },

        methods: {
            logout() {
                localStorage.removeItem('token');
                this.$store.commit('logout');

                this.$router.push({ name: 'Login', query: {action: 'logout'}});
            },

            setTheme(theme) {
                const name = theme.name;
                const dark = theme.dark;
                const light = theme.light;
                if (theme.name === 'simplecast' || theme.name === 'stitcher') {
                    this.darkMode = false;
                    this.$vuetify.theme.dark = false;
                }
                // set themes
                Object.keys(dark).forEach(i => {
                    this.$vuetify.theme.themes.dark[i] = dark[i];
                });
                Object.keys(light).forEach(i => {
                    this.$vuetify.theme.themes.light[i] = light[i];
                });
                // also save theme name to disable selection
                this.$vuetify.theme.themes.name = name;
            },
        },

        mounted() {
            this.setTheme(find(this.themes, {name: 'sxm'}));

            this.darkMode = useDark({
                onChanged: (dark) => {
                    this.$vuetify.theme.dark = dark;
                }
            });
        }
    };
</script>
